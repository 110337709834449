@import 'src/styles/colors';

.master-search {
  &-header {
    padding: 32px 16px;
    padding-top: 0px;
  }

  &-title {
    font-weight: 600;
    color: $dyke-brown;
    font-size: 1.125rem;
    margin-bottom: 16px;
    line-height: 1.375rem;
  }

  &-operations {
    width: 100%;
    max-width: 768px;
    position: relative;
  }
}

.search-input {
  width: 100%;
  font-weight: 600;
  padding: 8px 12px;
  color: $dyke-brown;
  border-radius: 0px;
  padding-right: 112px;
  font-size: 1.143rem;
  line-height: 1.429rem;
  border: 1px solid transparent;

  &:hover,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.085);
  }
}

.search-submit {
  top: 50%;
  right: 0px;
  border: 0px;
  width: 100px;
  color: $black;
  cursor: pointer;
  font-weight: 600;
  padding: 6px 12px;
  position: absolute;
  font-size: 0.875rem;
  line-height: 1.125rem;
  transform: translateY(-50%);
  background-color: transparent;

  i {
    line-height: 1;
    margin-left: 4px;
  }
}

.search-pill {
  display: flex;
  margin-top: 8px;
  padding: 4px 8px;
  user-select: none;
  margin-right: 8px;
  border-radius: 24px;
  align-items: center;
  max-width: fit-content;
  justify-content: center;
  background-color: $dyke-brown;

  &-text {
    flex: 1;
    color: $white;
    font-weight: 600;
    margin-right: 4px;
    line-height: 1rem;
    font-size: 0.75rem;
    display: inline-block;
  }

  &-action {
    padding: 4px;
    border: none;
    color: $white;
    line-height: 1;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 800;
    background-color: transparent;
  }
}
