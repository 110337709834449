@import 'src/styles/colors';

.page-view {
  &-header {
    padding: 3em 40px;
    padding-bottom: 32px;
  }

  &-title {
    max-width: 85%;
    font-weight: 700;
    color: $dyke-brown;
    font-size: 1.375rem;
    line-height: 1.75rem;
  }

  &-content {
    display: flex;
    padding: 0px 40px;
    padding-bottom: 80px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media only screen and (min-width: 992px) {
      flex-direction: row;
    }
  }

  &-static {
    position: relative;
  }

  &-conceal {
    top: 0px;
    left: 0px;
    z-index: 6;
    right: 0px;
    bottom: 0px;
    position: absolute;
  }
}

.left-content {
  padding: 24px;
  overflow: hidden;
  max-width: 512px;
  position: relative;
  margin-bottom: 24px;
  background-color: $white;

  @media only screen and (min-width: 992px) {
    margin-right: 16px;
    margin-bottom: 0px;
  }

  &-conceal {
    top: 0px;
    left: 0px;
    z-index: 6;
    right: 0px;
    bottom: 0px;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;

    p {
      font-weight: 800;
      font-size: 2.125rem;
      line-height: 2.375rem;
      transform: rotate(-45deg);
      color: rgba(0, 0, 0, 0.15);
    }
  }

  &-photo {
    height: auto;
    max-width: 100%;
  }
}

.right-content {
  flex: 1;

  @media only screen and (min-width: 992px) {
    margin-left: 16px;
    margin-bottom: 0px;
  }
}

.content {
  &-datapoint {
    display: flex;
    font-size: 1rem;
    padding: 16px 0px;
    line-height: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.045);

    &:first-of-type {
      padding-top: 0px;
    }

    &:last-of-type {
      border-bottom: 0px;
      margin-bottom: 40px;
      padding-bottom: 0px;
    }

    &-label {
      font-weight: 600;
      margin-right: 8px;
      padding-right: 8px;
      display: inline-block;
      border-right: 1px solid rgba(0, 0, 0, 0.045);
    }

    &-value {
      flex: 1;
      display: inline-block;
    }

    &-pill {
      padding: 4px 8px;
      margin-right: 16px;
      margin-bottom: 8px;
      font-size: 0.875rem;
      border-radius: 10px;
      line-height: 1.125rem;
      background-color: rgba(0, 0, 0, 0.045);
      box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.095);

      & > i {
        margin-right: 4px;
        color: lighten($black, 35%);
      }
    }

    &-subtitle {
      display: block;
      margin-bottom: 8px;
      text-decoration: underline;
    }

    &-list {
      margin: 0px;
      padding: 0px;
      padding-left: 18px;

      & > li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  &-action {
    border: 0px;
    color: $white;
    cursor: pointer;
    padding: 8px 16px;
    font-size: 1.125rem;
    border-radius: 10px;
    line-height: 1.375rem;
    background-color: $sheen-green;

    & > i {
      margin-right: 6px;
    }
  }
}
