@import 'src/styles/colors';

.page-home {
  display: flex;
  position: relative;

  &-container {
    padding-left: 240px;
    padding-bottom: 3em;
  }

  &-intro {
    padding: 32px 16px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.045);
  }

  &-text {
    color: $black;
    max-width: 85%;
    font-size: 400;
    font-size: 1rem;
    margin-bottom: 0px;
    line-height: 1.25rem;
  }

  &-grid {
    width: 100%;
    row-gap: 24px;
    display: grid;
    column-gap: 16px;
    padding: 8px 16px;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (min-width: 1400px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-empty {
    min-height: 50vh;
    padding: 8px 16px;

    .page-home-text {
      margin-top: 0px;
      font-weight: 600;
    }
  }

  &-pagination {
    display: flex;
    margin-top: 24px;
    align-items: center;
    justify-content: center;
  }
}

.pagination {
  &-action {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    padding: 6px 8px;
    color: $dyke-brown;
    line-height: 1.25rem;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.045);

    &:first-child {
      margin-right: 8px;

      i {
        line-height: 1;
        margin-right: 8px;
      }
    }

    &:last-child {
      margin-left: 8px;

      i {
        line-height: 1;
        margin-left: 8px;
      }
    }

    &:disabled {
      opacity: 0.65;
      cursor: not-allowed;
    }
  }

  &-text {
    color: $black;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
  }
}
