@import 'src/styles/colors';

.single-page-header {
  width: 100%;
  display: flex;
  padding: 8px 40px;
  align-items: center;
  justify-content: space-between;
}

.page-header {
  background-color: $white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.045);

  &-left,
  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-right {
    justify-content: flex-end;
  }

  &-title {
    flex: 1;
    color: $may-green;
    font-size: 2.286rem;
    line-height: 2.714rem;
  }

  &-brands {
    margin: 0px;
    padding: 0px;
    display: flex;

    align-items: center;
    list-style-type: none;

    li {
      height: 64px;
      padding-right: 8px;

      a {
        height: 64px;
        display: inline-block;
      }

      &:last-child {
        padding-right: 0px;
      }
    }

    img {
      height: auto;
      max-width: 100%;
      max-height: 64px;
    }
  }

  &-logout {
    border: 0px;
    margin: 0px;
    padding: 8px;
    color: $black;
    line-height: 1;
    cursor: pointer;
    margin-left: 24px;
    font-size: 1.25rem;
    background-color: transparent;
  }
}
