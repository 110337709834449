@import 'src/styles/colors';

.page-logout {
  &-container {
    padding: 3em 0px;
    margin-bottom: 0px;
  }

  &-loader {
    height: auto;
    max-width: 100%;
    margin-bottom: 8px;
  }

  &-loading {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &-heading {
    margin: 0px;
    font-weight: 700;
    color: $dyke-brown;
    margin-bottom: 16px;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  &-description {
    color: $black;
    font-size: 1rem;
    margin: 0px auto;
    max-width: 575px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px;
    line-height: 1.25rem;
  }
}
