@import 'src/styles/colors';

.page-home {
  &-container {
    padding: 3em 0px;
    margin-bottom: 0px;
  }

  &-loader {
    height: auto;
    max-width: 100%;
    margin-bottom: 8px;
  }

  &-loading {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &-heading {
    margin: 0px;
    font-weight: 700;
    color: $dyke-brown;
    margin-bottom: 16px;
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  &-description {
    color: $black;
    font-size: 1rem;
    margin: 0px auto;
    max-width: 575px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.25rem;
  }

  &-refresh {
    line-height: 1rem;
    font-size: 0.75rem;
    margin-bottom: 16px;
  }

  &-retry {
    width: 100%;
    color: $black;
    cursor: pointer;
    line-height: 1rem;
    font-weight: 600;
    max-width: 125px;
    padding: 6px 12px;
    font-size: 0.75rem;
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid $sheen-green;

    &:hover,
    &:focus {
      color: $white;
      background-color: $sheen-green;
    }
  }
}
