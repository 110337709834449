@import 'src/styles/colors';

.aside-section {
  &-choices {
    margin: 0px;
    list-style-type: none;
    padding: 0px 0px 32px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.045);

    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.accordion-item {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0px;
  }

  .accordion-header {
    display: flex;
    cursor: pointer;
    margin-bottom: 16px;
    padding-bottom: 8px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.045);
    justify-content: space-between;

    &-text {
      color: $black;
      display: block;
      font-weight: 500;
      margin-right: 8px;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }

  .accordion-body {
    .aside-section-choices {
      padding: 0px;
      border-bottom: none;
    }
  }
}
