@import 'src/styles/colors';

.grid-element {
  width: 100%;
  display: flex;
  user-select: none;
  position: relative;
  flex-direction: column;
  box-shadow: 2px 2px 6px 4px rgba(0, 0, 0, 0.045);

  &:hover,
  &:focus {
    .grid-element-photo {
      transform: scale(1.04);
      transition: ease-in-out all 0.25s;
    }

    .grid-element-instructions {
      display: flex;
    }
  }

  &-subject {
    top: -12px;
    left: -4px;
    z-index: 10;
    color: $black;
    font-weight: 700;
    padding: 4px 8px;
    line-height: 1rem;
    font-size: 0.75rem;
    border-radius: 6px;
    position: absolute;
    width: fit-content;
    display: inline-block;
    background-color: $black;
    border: 1px solid rgba(0, 0, 0, 0.085);
    box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.1);
  }

  &-image {
    width: 100%;
    padding: 16px;
    height: 160px;
    overflow: hidden;
    background-color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.065);
  }

  &-photo {
    height: auto;
    display: block;
    max-width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &-content {
    flex: 1;
    display: flex;
    padding: 16px;
    cursor: pointer;
    position: relative;
    padding-bottom: 32px;
    flex-direction: column;
    background-color: $white;
    border-top: 1px solid rgba(0, 0, 0, 0.085);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.065);
  }

  &-title {
    flex: 1;
    color: $black;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 24px;
    line-height: 1.25rem;
  }

  &-metadata {
    margin-bottom: 8px;
    font-size: 0.875rem;
    line-height: 1.125rem;

    & > p {
      margin: 0px;
    }
  }

  &-instructions {
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: none;
    padding: 4px 16px;
    padding-top: 6px;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: $sheen-green;
  }

  &-helptext {
    color: $white;
    font-weight: 300;
    line-height: 1rem;
    font-size: 0.714rem;
  }
}
