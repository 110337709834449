@import 'src/styles/colors';

.page-loader {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  z-index: 99999;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);

  &-image {
    margin-bottom: 8px;
  }

  &-title {
    color: $coffee;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
