@import 'src/styles/colors';

.print-frame {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  padding: 16px;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.95);

  &-header {
    width: 100%;
    max-width: 767px;
    text-align: center;
    position: relative;
    margin-bottom: 24px;

    i {
      top: -10px;
      width: 40px;
      right: 56px;
      height: 40px;
      display: flex;
      line-height: 1;
      font-size: 1.5rem;
      border-radius: 50%;
      position: absolute;
      align-items: center;
      color: $may-green;
      justify-content: center;
      border: 1px solid $may-green;
      animation: animatedBlink 2s linear 0s infinite normal both;
    }
  }

  &-instructions {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }

  &-embed {
    width: 100%;
    max-width: 767px;
    height: calc(100vh - 200px);
  }
}

@keyframes animatedBlink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}
