.aside-help {
  &-trigger,
  &-close {
    top: 12px;
    right: 12px;
    border: 0px;
    line-height: 1;
    cursor: pointer;
    color: $may-green;
    position: absolute;
    width: fit-content;
    font-size: 1.125rem;
    background-color: transparent;
  }

  &-close {
    color: $black;
  }

  &-dialog {
    top: 88px;
    left: 248px;
    z-index: 99;
    width: 100%;
    color: $black;
    max-width: 232px;
    padding: 32px 16px;
    padding-top: 48px;
    position: absolute;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.085);
    box-shadow: 0px 0px 4px 6px rgba(0, 0, 0, 0.065);
  }

  &-instructions {
    margin: 0px;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
