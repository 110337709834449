@import 'src/styles/colors';

.singlecheck {
  &-label {
    display: flex;
    cursor: pointer;
    width: fit-content;
    position: relative;
    align-items: center;
    justify-content: flex-start;

    &-selector {
      width: 18px;
      height: 18px;
      display: flex;
      margin-right: 8px;
      border-radius: 9px;
      margin-bottom: 3px;
      align-items: center;
      justify-content: center;
      background-color: $white;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }

    &-icon {
      width: 9px;
      height: 9px;
      display: none;
      border-radius: 50%;
      background-color: $white;
    }

    &-input {
      width: 1px;
      height: 1px;
      margin: 0px;
      border: 0px;
      top: -9999px;
      left: -9999px;
      position: absolute;

      &:checked {
        & ~ .singlecheck-label-selector {
          background-color: $may-green;

          & > .singlecheck-label-icon {
            display: inline-block;
          }
        }
      }
    }

    &-text {
      color: $black;
      font-weight: 400;
      font-size: 0.857rem;
      line-height: 1.143rem;
    }
  }
}
