@import 'src/styles/colors';

.page-home {
  &-aside {
    flex: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    max-width: 240px;
    padding: 40px 0px;
    position: absolute;
    flex-direction: column;
    background-color: $white;
    border-right: 1px solid rgba(0, 0, 0, 0.045);
  }
}

.aside-section {
  padding: 0px 16px;
  margin-bottom: 32px;

  &-title {
    color: $black;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 16px;
    line-height: 1.286rem;
  }

  &-choices {
    margin: 0px;
    list-style-type: none;
    padding: 0px 0px 32px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.045);

    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &:last-child {
    margin-bottom: 0px;

    .aside-section-choices {
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}
