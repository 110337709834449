* {
  outline: none;
  user-select: none;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &:not(i) {
    font-family: 'Nunito Sans', sans-serif !important;
  }
}

html,
body {
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  display: block;
  font-weight: 700;
}

::placeholder {
  color: rgba(0, 0, 0, 0.25);
}

body {
  background-color: rgba(64, 64, 64, 0.085);
}

.lock-scroll {
  overflow: hidden !important;
}

a {
  outline: none;
  font-weight: 600;
  color: $may-green;
  text-decoration: none;

  &:hover,
  &:focus,
  &:visited {
    color: $may-green;
  }
}
